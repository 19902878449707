<template>
  <div v-can="'RepMedPerf1'">
    <section class="header">
      <div class="title">Perfis personalizados</div>

      <b-button 
        v-can="'RepMedPerf2'"
        :disabled="!hasConfiguration" 
        variant="primary" 
        @click="openProfileModal({})"
      >
        Novo perfil
      </b-button>
    </section>

    <section class="flex-profiles" v-if="profiles && profiles.length && hasConfiguration">
      <v-profile-card
        v-for="profile in profiles"
        :key="profile.id"
        :profile="profile"
        @openProfileModal="openProfileModal"
        @updateProfile="getProfiles"
        @deleteProfile="getProfiles"
        @change-tab="changeTab"
      />
    </section>

    <b-row v-else>
      <b-col class="empty">
        Configure o repasse médico na aba 'Configurações' para criar perfis
      </b-col>
    </b-row>

    <v-profile-modal
      ref="profileModal"
      :profile="profile"
      :profiles="profiles"
      @createProfile="getProfiles"
      @updateProfile="getProfiles"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import api from '../api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import ProfileCard from '@/modules/onlending/components/profile/ProfileCard'
import ProfileModal from '@/modules/onlending/modals/profile/ProfileModal'

export default {
  name: 'Profiles',
  metaInfo: {
    title: 'Eyecare - Repasse médico - Perfis'
  },
  components: {
    'v-profile-card': ProfileCard,
    'v-profile-modal': ProfileModal
  },
  created() {
    this.getProfiles()
  },
  props: {
    hasConfiguration: Boolean
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      profiles: null,
      profile: {}
    }
  },
  methods: {
    getProfiles() {
      const isLoading = this.$loading.show()
      api.getOnlendingProfilesByClinicId(this.clinic.id)
      .then((res) => {
        this.profiles = res.data
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },
    changeTab(data) {
      this.$emit('change-tab', data)
    },
    openProfileModal(profile) {
      this.profile = profile
      this.$bvModal.show('profile-modal')
    },
    closeModal() {
      this.profile = {}
      this.getProfiles()
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;

  .title {
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: var(--type-active);
    display: flex;
    align-items: center;
  }
}
.flex-profiles {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0 0 3rem;

  @media (max-width: 1000px) {
    flex-direction: column;

    .profile-card {
      width: 100%;
    }
  }
}

.empty{
  text-align: center;
  padding: 100px 0px;
  color: var(--type-active);
}
</style>