<template>
  <b-modal
    v-can="'RepMedPerf3' || 'RepMedPerf2'"
    id="profile-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title" v-if="profile && profile.id">
        Alterar perfil de repasse
      </div>
      <div class="modal-title" v-else>Adicionar novo perfil de repasse</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="profile_name">Nome do perfil</label>
            <b-input
              id="profile_name"
              autocomplete="off"
              placeholder="Descrever"
              v-model="form.name"
            />
            <div v-if="validated && !form.name" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
            <div v-if="validated && duplicateName" class="custom-invalid-feedback text-right">
              Este nome de perfil já existe, tente um nome diferente
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="description">
              Descrição
              <span class="help" id="help">(opcional)</span>
            </label>
            <div>
              <TextArea
                id="description"
                v-model="form.description"
                placeholder="Descrever"
                rows="3"
                max-rows="6"
                maxlength="500"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button
          variant="primary"
          v-if="profile && profile.id"
          size="lg"
          @click="updateProfile"
        >
          Atualizar perfil
        </b-button>
        <b-button variant="primary" size="lg" v-else @click="createProfile">
          Salvar perfil
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import api from '@/modules/onlending/api.js'

export default {
  name: 'ProfileModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    TextArea: () => import('@/components/General/TextArea')
  },
  props: {
    profile: {
      type: Object,
      default: () => ({})
    },

    profiles: {
      type: Array,
      default: () => ({})
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      form: this.defaultForm(),
      validated: false,
      duplicateName: false
    }
  },
  methods: {
    isValidForm() {
      this.validated = true
      const prof = this.profiles.find(option => (option.name.trim()).toLowerCase() === (this.form.name.trim()).toLowerCase())
      if(prof && prof.id !== this.profile.id){
        this.duplicateName = true
        return
      }

      return Boolean(this.form.name)
    },
    createProfile() {
      if (!this.isValidForm()) return
      const loading = this.$loading.show()
      this.form.active = true
      api.createOnlendingProfile({
        ...this.form,
        clinic_id: this.clinic.id
      })
      .then(() => {
        this.$emit('createProfile')
        this.$toast.success('Perfil criado com sucesso!')
        this.closeModal()
      })
      .catch((error) => {
        this.$toast.error(error.message)
      })
      .finally(() => {
        loading.hide()
      })
    },
    updateProfile() {
      if (!this.isValidForm()) return
      const loading = this.$loading.show()
      api.updateOnlendingProfile(this.profile.id, 
      {
        ...this.form,
        clinic_id: this.clinic.id
      })
      .then(() => {
        this.$emit('updateProfile')
        this.$toast.success('Perfil atualizado com sucesso!')
        this.closeModal()    
      })
      .finally(() => {
        loading.hide()
      })
    },
    defaultForm() {
      return {
        name: '',
        description: ''
      }
    },
    closeModal() {
      this.form = this.defaultForm()
      this.$emit('closeModal')
      this.validated = false
      this.$bvModal.hide('profile-modal')
    }
  },
  watch: {
    profile: {
      handler() {
        this.form = this.profile
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#profile-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 0 0px 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>