<template>
  <div class="profile-card">
    <b-row class="name d-flex inline justify-content-between px-2">
        <h2>{{ profile.name }}</h2>

        <Trash 
          v-can="'RepMedPerf5'"
          v-if="!profile.auto"
          class="icon" 
          @click="deleteProfileClick" 
        />
    </b-row>

    <b-row class="description">
      <b-col class="col-description" :class="{ pointer: isTooltip() }">
        <p :id="`col_description_${profile.id}`">
          <strong>Descrição:</strong>
          <span v-if="profile.description">
            {{ profile.description }}
          </span>
          <span v-else class="no-description"> (Não há descrição) </span>
        </p>
      </b-col>
      <b-tooltip
        v-if="isTooltip()"
        class="tooltip-description"
        :target="`col_description_${profile.id}`"
        triggers="hover"
        placement="bottom"
      >
        <span class="">
          {{ profile.description }}
        </span>
      </b-tooltip>
    </b-row>

    <hr class="divider" />
    <b-row class="h-button d-flex">
      <b-col 
        v-can="'RepMedPerf3'"
        cols="12"
        md="7"
        class=""
      >
        <span>
          {{ profile.active ? 'Ativo' : 'Inativo' }}
        </span>
        <toggle-button
          :disabled="!profile.clinic_id"
          class="autoFillBtn"
          :width="30"
          :value="Boolean(profile.active)"
          :sync="true"
          :height="16"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          @change="updateProfile"
        />
      </b-col>
      <b-col 
        v-can="'RepMedPerf4'"
        cols="12"
        md="5"
        class="d-flex justify-content-end"
      >
        <b-button           
          v-if="!profile.auto"
          variant="link" 
          @click="openProfileModal()"
        >
          Editar perfil
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Trash from '@/assets/icons/trash.svg'
import api from "@/modules/onlending/api.js"

export default {
  name: 'ProfileCard',
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  components: {
    Trash
  },
  methods: {
    isTooltip() {
      return this.profile.description && this.profile.description.length >= 100
    },
    updateActive() {
      this.profile.active = !this.profile.active
    },
    updateProfile() {
      const isLoading = this.$loading.show()
      this.updateActive()
      api.updateOnlendingProfile(
        this.profile.id,
        this.profile
      )
      .then(() => {
        this.$emit('updateProfile')
        if (this.profile.active) {
          this.$toast.success('Perfil ativado com sucesso!')
        } else {
          this.$toast.success('Perfil desativado com sucesso!')
        }
      })        
      .catch ((error) => {
        this.$toast.error(error.message)
      }) 
      .finally (() => {
        isLoading.hide()
      })
    },
    deleteProfileClick() {
      if(!this.profile.definitions.length){        
        this.$swal({
          icon: 'question',
          html: `<div class="body-alert">Deseja realmente excluir este perfil? Essa ação não poderá ser desfeita.</div>`,
          showCancelButton: true,
          confirmButtonColor: '#F63220',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then(res => {
          if (res.isConfirmed) {
            this.deleteProfile()
          }
        })
      }
      else{        
        let rules = ''
        this.profile.definitions.forEach(definition => {
          rules += `"Regra #${definition.rule.number} - ${definition.rule.name}" <br/>`
        });

        this.$swal({
          icon: 'info',
          html: `<h2><b>Importante!</b></h2><div class="body-alert">Para excluir este perfil é necessário editar antes a(s).</div><b>${rules}</b> `,
          showCancelButton: true,
          confirmButtonColor: '#305BF2',
          confirmButtonText: 'Ir para regra',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then(res => {
          if (res.isConfirmed) this.$emit('change-tab', { tab: 'regras-de-repasse', index: 3 })
        })
      }
    },
    deleteProfile() {
      const isLoading = this.$loading.show()
      api.deleteOnlendingProfile(
        this.profile.id
      )
      .then(() => {          
        this.$emit('deleteProfile')
        this.$toast.success('Perfil excluído com sucesso')
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally(() => {
        isLoading.hide()
      })
    },
    openProfileModal(){
      this.$emit('openProfileModal', this.profile)
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--neutral-300);
    border-radius: 100px;
  }
}

:deep(.tooltip-inner) {
  width: 300px !important;
  max-height: 400px !important;
  overflow-y: auto;
}
.profile-card {
  box-sizing: border-box;
  width: calc(25% - 36px / 3);
  text-align: left;
  background: var(--neutral-000);
  border: 1px solid var(--neutral-200);
  border-radius: 8px;

  h2 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    stroke: var(--states-red-soft);
  }

  .name {
    padding: 1rem !important;
    margin: 0;
  }
  
  .description {
    height: 72px;
    text-overflow: ellipsis;
    padding: 0 1rem 1.2rem;
    margin: 0;

    .col-description {
      padding: 0;
      &.pointer {
        cursor: pointer;
      }

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        margin: 0;

        .no-description {
          color: var(--type-placeholder);
        }
      }
    }
  }

  .divider {
    margin: 0;
  }
  .h-button{
    margin: 0;
    padding:  1rem;

    & > div {
      padding: 0;
      font-size: 1em;
    }
  }
}
</style>
